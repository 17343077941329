.cslider::-webkit-slider-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 7px;
    cursor: pointer;
    transition: background-color 0.2s;
    background-color: #888;
}

.cslider.vertical::-webkit-slider-thumb {
    width: 50px;
    height: 50px;
    border-radius: 10px;
}

.csliderroot:active input[type="range"]::-webkit-slider-thumb {
    background-color: rgb(27, 28, 29);
}

.cslider.hue {
    background: linear-gradient(to right, hsl(0, 100%, 50%) 0%, hsl(36, 100%, 50%) 10%, hsl(72, 100%, 50%) 20%, hsl(108, 100%, 50%) 30%, hsl(144, 100%, 50%) 40%, hsl(180, 100%, 50%) 50%, hsl(216, 100%, 50%) 60%, hsl(252, 100%, 50%) 70%, hsl(288, 100%, 50%) 80%, hsl(324, 100%, 50%) 90%, hsl(360, 100%, 50%) 100%);
}
